import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/components/HomePage.vue';

const routes = [
  { path: '/', component: HomePage },
];

const router = new createRouter({
  history: createWebHistory(), // 使用HTML5 History模式
  routes,
});

export default router;