
<template>
  <router-view v-slot="{ Component }" :key="$route.fullPath">
   <keep-alive>
     <component :is="Component" />
   </keep-alive>
 </router-view>
</template>

<style>
* {
 margin: 0;
 padding: 0;
}
#app {
 font-family: Avenir, Helvetica, Arial, sans-serif;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
 text-align: center;
 color: #2c3e50;
 /* margin-top: 60px; */
}
html, body {
 background-color: #FFFFFF;
 margin: 0;
 padding: 0;
 height: 100%; /* 确保高度占满整个视口 */
 width: 100%;
}

</style>