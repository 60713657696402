
<template>

<div class="content-top">
      <img src="../assets/ic_logo.png" alt="" style="height: 35pt;width: 35pt;">
      <div class="content-top-info" ref="homeDiv">
        <p style="color: #999999;font-size: 8pt;">TECHNOLOGY</p>
        <p style="color: #FFFFFF;font-size: 15pt;"> 鑫新未来 </p>
      </div>
      <div class="content-top-tab">
        <div class="tab-item">
          <p class="tab-item-title" @click="homeAction"> 首页 </p>
        </div>
        <div class="tab-item" @click="productAction">
          <p class="tab-item-title"> 产品服务 </p>
        </div>
        <div class="tab-item">
          <p class="tab-item-title" @click="advanceAction"> 优势 </p>
        </div>
        <div class="tab-item">
          <p class="tab-item-title" @click="aboutUsAction"> 关于我们 </p>
        </div>
      </div>
  </div>
  <div class="home-top-bg">
    <div style="margin-left: 15rem;margin-top: 2.5rem;">
      <p style="color: white;font-size: 30pt;font-weight: bold;"> 科技 & 服务 </p>
    </div>
    <div style="margin-left: 15rem;margin-top: 20pt; width: 30%;text-align: start;">
      <p style="color: white;font-size: 14pt;"> 服务众多客户，提供优秀的网站、客户端、app制作服务；一家集策划、设计、制作与一体的网站建设服务商。 </p>
    </div>
  </div>

  <div class="home-product" ref="produceDiv">
    <div class="home-title">
      <div class="home-product-title-row">
        <p style="color: black;font-size: 24pt;"> 产品与服务 </p>
        <p style="color: gray; font-size: 14pt;margin-left: 10pt;"> WE DO </p>
      </div>
      <div class="line"></div>
    </div>
    <div style="display: flex;flex-direction: row;width: 70%;margin-top: 2.5rem;">
      <div class="product-item">
        <img src="../assets/ic_product_pc.png" alt="" style="height: 91px;margin-bottom: 1rem;">
        <p style="font-size: 18pt;color: black;font-weight: 500;"> PC网站 </p>
        <p> 专业资深的网站设计团队，针对有品牌意识的企业 </p>
      </div>
      <div class="product-item">
        <img src="../assets/ic_app.png" alt="" style="height: 91px; margin-bottom: 1rem;">
        <p style="font-size: 18pt;color: black;font-weight: 500;"> 移动APP </p>
        <p> 8年移动端App项目经验，一站式解决方案多重产品及配套服务 </p>
      </div>
      <div class="product-item">
        <img src="../assets/ic_h5.png" alt="" style="height: 91px;margin-bottom: 1rem;">
        <p style="font-size: 18pt;color: black;font-weight: 500;"> H5网站 </p>
        <p> 我们提供持续的技术支持和定期更新，确保您的H5应用始终保持最新状态，满足不断变化的市场需求。</p>
      </div>
      <div class="product-item">
        <img src="../assets/ic_pc_app.png" alt="" style="height: 91px;margin-bottom: 1rem;">
        <p style="font-size: 18pt;color: black;font-weight: 500;"> 桌面端程序 </p>
        <p> 我们专注于为Windows和macOS用户提供高品质、高性能的桌面端工具开发服务。 </p>
      </div>
    </div>
  </div>

  <div class="home-server" ref="serverDiv">
    <div class="home-title">
      <div class="home-product-title-row">
        <p style="color: black;font-size: 24pt;margin-top: 2rem;"> 服务优势 </p>
        <p style="color: gray; font-size: 14pt;margin-left: 10pt;"> Advantage </p>
      </div>
      <div class="line"></div>
    </div>

    <div
      style="display: flex;flex-direction: column;width: 70%; align-items: center;margin-top: 30pt;min-width: 756px;">

      <div class="server-item">
        <img src="../assets/ic_server_item1.png" alt="" style="width: 40%;">
        <div class="server-item-right">
          <p class="server-item-sub-title"> 专业技术团队+UI设计团队 </p>
          <p> 拥有8年以上项目经验，所承接项目均提供vip一对一服务 </p>
          <div class="server-item-right-btn" @click="showModalAction">
            <p style="color: white;"> 立即咨询 </p>
          </div>
        </div>
      </div>

      <div class="server-item-img-right">
        <div class="server-item-left">
          <p class="server-item-sub-title"> VIP服务按期交付 </p>
          <p style="text-align: left;"> 一次只承接一个项目，建群答疑，解決问题 </p>
          <div class="server-item-right-btn" @click="showModalAction">
            <p style="color: white;"> 立即咨询 </p>
          </div>
        </div>
        <img src="../assets/ic_server_item1.png" alt="" style="width: 40%;">
      </div>

      <div class="server-item">
        <img src="../assets/ic_server_item1.png" alt="" style="width: 40%;">
        <div class="server-item-right">
          <p class="server-item-sub-title"> 融合当下AI技术效率更高 </p>
          <p style="text-align: left;"> 所有技术人员均配备ai协作助手，为您提供基于ai的方案和建议，更专业，更客观 </p>
          <div class="server-item-right-btn" @click="showModalAction">
            <p style="color: white;"> 立即咨询 </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="home-contact" ref="contactDiv">
    <div class="home-title">
      <div class="home-product-title-row">
        <p style="color: black;font-size: 24pt;margin-top: 1rem;"> 联系我们 </p>
        <p style="color: gray; font-size: 14pt;margin-left: 10pt;"> Contect Us </p>
      </div>
      <div class="line"></div>
    </div>
    <div
      style="display: flex;flex-direction: row;justify-content: center;width: 70%;align-items: start;margin-top: 2rem;">
      <div style="display: flex;flex-direction: column; width: 35%;">
        <p style="font-size: 15pt;text-align: start;font-weight: 400;"> 关于我们 </p>
        <p style="font-size: 12pt;text-align: start;margin-top: 5pt;">
          武汉鑫新未来科技有限公司是一家提供网站（PC端和移动端）建设，客户端，App等技术外包的服务公司；公司拥有一批工龄8年+的成熟技术人员，和工龄
          5年+的UI设计，为您提供一站式外包服务；</p>
      </div>
      <div style="display: flex;flex-direction: column;align-items: start;margin-left: 10rem;">
        <p style="font-size: 15pt;text-align: start;font-weight: 400;"> 联系方式 </p>
        <div class="qr-code-img"></div>
        <label style="font-size: 11pt;text-align: start;margin-top: 5pt;">
          邮箱地址: xftigerzl@gmail.com<br>
          手机号码: 13512828952<br>
        </label>
      </div>
    </div>
  </div>

  <div class="icp_bottom">
    <p style="font-size: 15pt;"> 武汉鑫新未来科技有限公司 </p>
    <a href="https://beian.miit.gov.cn" style="font-size: 11pt;color: #999999;"> 鄂ICP备2024063541号-1 > </a>
  </div>

  <div class="modal" v-show="showModal" @click="hideModalAction">
    <div style="position: relative;margin: auto;">
      <div class="modal-content" @click.stop="childClick">
        <p style="margin-bottom: 15pt;"> 扫码添加微信 </p>
        <div class="qr-code-img"></div>
        <label style="font-size: 11pt;text-align: center;margin-top: 5pt;">
          邮箱地址: xftigerzl@gmail.com<br>
          手机号码: 13512828952<br>
        </label>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'NotFound',
  mounted() {
    document.title = "鑫新未来"
  },

  data() {
    return {
      showModal: false
    }
  },

  methods: {
    /// 首页
    homeAction() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 平滑滚动
      });
      // this.$refs.homeDiv.scrollIntoView({ behavior: 'smooth' });
    },
    /// 产品服务
    productAction() {
      this.$refs.produceDiv.scrollIntoView({ behavior: 'smooth' });
    },
    /// 产品优势
    advanceAction() {
      this.$refs.serverDiv.scrollIntoView({ behavior: 'smooth' });
    },
    /// 关于我们
    aboutUsAction() {
      this.$refs.contactDiv.scrollIntoView({ behavior: 'smooth' });
    },
    /// 隐藏弹窗
    hideModalAction() {
      this.showModal = false
      document.body.style.overflow = 'scroll';
    },
    /// 显示弹窗
    showModalAction() {
      this.showModal = true
      document.body.style.overflow = 'hidden';
    },

    childClick() {

    },
  },
}
</script>

<style>
.home-top-bg {
  background-image: url('~@/assets/ic_home_bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  min-width: 1080px;
  aspect-ratio: 1920 / 458.0;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.content-top {
  height: 4rem;
  position: sticky;
  top: 0pt;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 14rem;
  padding-right: 15pt;
  /* background-color: white; */
  background-color: #4A42C5DD;
}

.content-top-info {
  display: flex;
  flex-direction: column;
  margin-left: 5pt;
}

.content-top-tab {
  display: flex;
  flex-direction: row;
  padding-left: 1.5rem;
  cursor: pointer;
}

.tab-item {
  padding-left: 15pt;
  padding-right: 15pt;
}

.tab-item-title {
  color: white; 
  font-size: 14pt;
}

.tab-item-title:hover {
  color: #DDDDDD;
}

.home-product {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-title {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.home-product-title-row {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.line {
  height: 1pt;
  width: 100%;
  background-color: gray;
}

.product-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.home-server {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.server-item {
  display: flex;
  flex-direction: row;
  height: 9rem;
  background-color: #F3E2FA;
  margin-bottom: 2rem;
}

.server-item-img-right {
  display: flex;
  flex-direction: row;
  justify-content: end;
  height: 9rem;
  background-color: #F3E2FA;
  margin-bottom: 2rem;
}

.server-item-right {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  margin-left: 1.5rem;
  padding-right: 15pt;
  padding-top: 8pt;
  padding-bottom: 8pt;
}

.server-item-left {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
  margin-right: 1.5rem;
  padding-left: 15pt;
  padding-top: 8pt;
  padding-bottom: 8pt;
}

.server-item-right-btn {
  display: flex;
  border-radius: 15pt;
  background-color: blue;
  height: 30pt;
  padding-left: 10pt;
  padding-right: 10pt;
  align-items: center;
  cursor: pointer;
}

.server-item-sub-title {
  font-size: 15pt;
  font-weight: 500;
  color: black;
}

.home-contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icp_bottom {
  margin-top: 1rem;
  padding-bottom: 1rem;
}

.qr-code-img {
  background-image: url('~@/assets/ic_qr_code.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 80pt; 
  width: 80pt;
}

.modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 8pt;
  /* padding-left: 2rem;
  padding-right: 2rem; */
  padding: 2rem;
}

</style>